import * as React from 'react';
import {
  Box,
  Button,
  Flex,
  Heading,
  Table,
  Text,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Skeleton,
  Stack,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { Link } from 'gatsby';

const ManageProjectsView = (): JSX.Element => {
  // const toProject = (id: string) => {
  //   navigate(`/app/project/view/${id}`);
  // };
  const { projects } = useSelector((state: any) => state.projects);
  return (
    <Flex
      id="project-form"
      direction="column"
      bgColor="#fff"
      height="100%"
      marginX="40px"
      maxW="100%"
      padding="20px"
    >
      <Heading fontSize="26pt" marginLeft="2rem" marginBottom="20px">
        Manage Projects
      </Heading>
      <Box borderY="1px" marginLeft="2rem" borderColor="gray.200">
        <Flex
          maxW="100%"
          marginLeft="20px"
          marginRight="50px"
          marginY="20px"
          justify="space-between"
          align="flex-end"
          direction={{ base: 'column', md: 'row' }}
        >
          <Text>Below are the projects created in this Openscreen account</Text>
          <Button
            color="#fff"
            colorScheme="gray"
            _hover={{
              bgColor: '#000',
            }}
            bgColor="#4fc1ff"
            maxW="450px"
            paddingY="25px"
            borderRadius="25px"
          >
            Add Project
          </Button>
        </Flex>
        <Table variant="striped">
          <Thead>
            <Tr>
              <Th maxW="1vw">Projects</Th>
              <Th>View</Th>
            </Tr>
          </Thead>
          <Tbody>
            {projects.map(
              (e: {
                projectId: string;
                name:
                  | boolean
                  | React.ReactChild
                  | React.ReactFragment
                  | React.ReactPortal;
              }) => {
                return (
                  <Tr>
                    <Td>{e.name}</Td>
                    <Td>
                      <Link
                        className="links"
                        to={`/app/project/view/${e.projectId}`}
                      >
                        View
                      </Link>
                    </Td>
                  </Tr>
                );
              }
            )}
          </Tbody>
        </Table>
        {!projects ? (
          <Stack maxH="80vh">
            <Skeleton height="70px" />
            <Skeleton height="70px" />
            <Skeleton height="70px" />
            <Skeleton height="70px" />
            <Skeleton height="70px" />
            <Skeleton height="70px" />
            <Skeleton height="70px" />
            <Skeleton height="70px" />
            <Skeleton height="70px" />
          </Stack>
        ) : (
          <></>
        )}
      </Box>
    </Flex>
  );
};

export default ManageProjectsView;
